import RatingIcon from "@shared/assets/icons/rating.svg";
import { useRandomColor } from "@shared/utils/color/randomColor.ts";
import { memo } from "react";

import { Box } from "../../../box";
import { EntityAvatar } from "../../../entityAvatar";
import { OverflowTooltip } from "../../../tooltip";
import { Typography } from "../../../typography";
import css from "./TableAvatar.module.scss";
import { TableAvatarProps } from "./TableAvatar.types.ts";

export const TableAvatarContent = memo(
  ({
    name,
    id,
    surname,
    logo,
    title,
    size,
    description,
    rating,
    type = "default",
  }: TableAvatarProps) => {
    const styles = useRandomColor({ id: Number(id), options: { size: "4px", type } });

    return (
      <>
        <Box alignItems="center" justifyContent="center" style={styles} className={css.wrapper}>
          <EntityAvatar name={name} surname={surname} logo={logo} size={size} />
        </Box>
        <Box justifyContent="center" className={css.titlesContainer} truncate>
          <OverflowTooltip content={title}>
            <Typography variant="body2bold" fontWeight="bold" className={css.title} truncate>
              {title}
            </Typography>
          </OverflowTooltip>
          <OverflowTooltip content={description}>
            <Typography variant="body4" color="secondary" className={css.subTitle} truncate>
              {description}
            </Typography>
          </OverflowTooltip>
        </Box>
        {rating && (
          <Box alignItems="center" direction="row" justifyContent="center" className="gap-1">
            <RatingIcon />
            <span className={css.rating}>{rating}</span>
          </Box>
        )}
      </>
    );
  },
);
