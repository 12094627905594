import ChartAreaIcon from "@shared/assets/icons/pageList/chart-area.svg";
import { AppRoutes } from "@shared/constants/appRoutes.constant.ts";
import { useTranslation } from "react-i18next";

export const useAnalyticsItems = (id?: string) => {
  const { t } = useTranslation();

  const items = [
    {
      title: t("general-info"),
      link: id ? AppRoutes.ANALYTICS_GENERAL_INFORMATION.replace(":clientId", id) : AppRoutes.OTHER,
      icon: <ChartAreaIcon />,
    },
  ];

  return {
    items,
  };
};
