import cn from "classnames";
import { Link, useLocation } from "react-router-dom";

import { Box } from "../../box";
import { Typography } from "../../typography";
import { TabProps } from "../tabs.types.ts";
import css from "./Tab.module.scss";

const Tab = ({ link, title, icon }: TabProps) => {
  const { pathname } = useLocation();
  const isActive = link === decodeURIComponent(pathname) || pathname.includes(link);

  return (
    <li>
      <Link
        to={link}
        className={cn(css.tab, {
          [css.active]: isActive,
        })}
      >
        <Box>{icon}</Box>
        <Typography
          variant="body2"
          truncate
          className={cn(css.title, {
            [css.active]: isActive,
          })}
        >
          {title}
        </Typography>
      </Link>
    </li>
  );
};

export default Tab;
