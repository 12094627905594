import { Analytics } from "@firebase/analytics";
import { createContext, useCallback, useContext } from "react";

export interface FirebaseContextType {
  analytics: Analytics;
}

export const FirebaseContext = createContext<FirebaseContextType | null>(null);

interface EventParams {
  remember_me?: string;
  status?: string;
  search_term?: string;
}

export const useFirebase = () => {
  const context = useContext(FirebaseContext);

  if (!context) {
    throw new Error("FirebaseContext not provided");
  }

  const { analytics } = context;

  const logEventToFirebase = useCallback(
    async (eventName: string, params?: EventParams) => {
      const { logEvent } = await import("firebase/analytics");

      if (analytics) {
        logEvent(analytics, eventName, {
          send_page_view: false,
          ...params,
        });
      } else {
        throw new Error("Firebase analytics not initialized");
      }
    },
    [analytics],
  );

  return { logEventToFirebase };
};
