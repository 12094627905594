import EquipmentIcon from "@shared/assets/icons/pageList/equipments.svg";
import PersonsIcon from "@shared/assets/icons/pageList/persons.svg";
import StatisticsIcon from "@shared/assets/icons/pageList/statistics.svg";
import { AppRoutes } from "@shared/constants/appRoutes.constant.ts";
import { useTranslation } from "react-i18next";

interface LocationItemsProps {
  clientId?: number | string;
  locationId?: number | string;
}

export const useLocationItems = ({ clientId, locationId }: LocationItemsProps) => {
  const { t } = useTranslation();

  const items = [
    {
      title: t("statistics-location"),
      link: `${AppRoutes.CLIENTS}${clientId}/${AppRoutes.LOCATIONS}/${locationId}/${AppRoutes.STATISTIC}`,
      icon: <StatisticsIcon />,
    },
    {
      title: t("persons-location"),
      link: `${AppRoutes.CLIENTS}${clientId}/${AppRoutes.LOCATIONS}/${locationId}/${AppRoutes.PERSONS}`,
      icon: <PersonsIcon />,
    },
    {
      title: t("equipment-location"),
      link: `${AppRoutes.CLIENTS}${clientId}/${AppRoutes.LOCATIONS}/${locationId}/${AppRoutes.EQUIPMENT}`,
      icon: <EquipmentIcon />,
    },
  ];

  return {
    items,
  };
};
