import { useEffect, useState } from "react";

interface RandomColorProps {
  id: number;
  options: {
    size: string;
    type?: "withBorder" | "default";
  };
}

export const generateColorFromId = (id: number, opacity = 1) => {
  const r = (id * 103) % 256;
  const g = (id * 149) % 256;
  const b = (id * 197) % 256;

  return `rgba(${r},${g},${b}, ${opacity})`;
};

export const useRandomColor = ({ id, options: { size, type } }: RandomColorProps) => {
  const [borderColor, setBorderColor] = useState("");
  const [backgroundColor, setBackgroundColor] = useState("");

  useEffect(() => {
    const generateColorsFromId = (id: number) => {
      const borderColor = generateColorFromId(id);
      const backgroundColor = generateColorFromId(id, 0.5);

      setBorderColor(borderColor);
      setBackgroundColor(backgroundColor);
    };

    generateColorsFromId(id);
  }, [id]);

  const isBorder = type === "withBorder" ? `${size} solid ${borderColor}` : "";

  return { background: backgroundColor, border: isBorder };
};
