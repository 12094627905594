export const Regular = {
  SPECIAL_CHARACTERS: /[!\\"#$%&'()*+,-./:;<=>?@/^_`{|}~]/,
  UPPERCASE_LETTERS: /[A-Z]/,
  LOWERCASE_LETTERS: /[a-z]/,
  NUMBERS: /[0-9]/,
  DATE_BIRTH: /^\d{4}-(0[1-9]|1[0-2])-(0[1-9]|[12]\d|3[01])$/,
  EMAIL: /^[a-zA-Z0-9!\\"#$%&'()*+,-./:;<=>?/^_`{|}~]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/,
  NAME: /^([a-zA-Zа-яА-ЯёЁ-]+(?:[\s-]?[a-zA-Zа-яА-ЯёЁ-]+)*)$/,
  COMPANY: /^(?!(?:.* ){5})[^+=[\]{};:\\|.<>/?`]*$/,
  ADDRESS: /^[A-Za-zА-Яа-я0-9\-\.,\/ ]*$/,
  PASSWORD: /^(?!.*(\d)\1{2}).*$/,
  FIELD: /^(?!(?:.* ){5})[^!@#$%^&*()_+=[\]{};':"\\|,.<>/?`]*$/,
  IP_ADDRESS:
    /((^\s*((([0-9]|[1-9][0-9]|1[0-9]{2}|2[0-4][0-9]|25[0-5])\.){3}([0-9]|[1-9][0-9]|1[0-9]{2}|2[0-4][0-9]|25[0-5]))\s*$)|(^\s*((([0-9a-f]{1,4}:){7}([0-9a-f]{1,4}|:))|(([0-9a-f]{1,4}:){6}(:[0-9a-f]{1,4}|((25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)(\.(25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)){3})|:))|(([0-9a-f]{1,4}:){5}(((:[0-9a-f]{1,4}){1,2})|:((25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)(\.(25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)){3})|:))|(([0-9a-f]{1,4}:){4}(((:[0-9a-f]{1,4}){1,3})|((:[0-9a-f]{1,4})?:((25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)(\.(25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)){3}))|:))|(([0-9a-f]{1,4}:){3}(((:[0-9a-f]{1,4}){1,4})|((:[0-9a-f]{1,4}){0,2}:((25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)(\.(25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)){3}))|:))|(([0-9a-f]{1,4}:){2}(((:[0-9a-f]{1,4}){1,5})|((:[0-9a-f]{1,4}){0,3}:((25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)(\.(25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)){3}))|:))|(([0-9a-f]{1,4}:){1}(((:[0-9a-f]{1,4}){1,6})|((:[0-9a-f]{1,4}){0,4}:((25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)(\.(25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)){3}))|:))|(:(((:[0-9a-f]{1,4}){1,7})|((:[0-9a-f]{1,4}){0,5}:((25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)(\.(25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)){3}))|:)))(%.+)?\s*$))/gi,
  ID: /^[a-zA-Z0-9]{1,}$/,
  VERSION: /^[a-zA-Z0-9.-]{1,}$/,
  MAC_ADDRESS: /([0-9a-fA-F]{2}([:-]|$)){6}$|([0-9a-fA-F]{4}([.]|$)){3}/,
};
